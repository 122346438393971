import React, { useEffect } from 'react';
import { WindowLocation } from '@reach/router';

import SEO from 'src/components/SEO';
import SignUpForm from 'src/components/auth/SignUpForm';
import withLocation from 'src/components/withLocation';

import Typography from '@mui/material/Typography';

// Styles
import * as authStyles from 'src/styles/auth.module.scss';
import { SIGN_UP_PATH } from 'src/constants/urls';
import useSegmentEvents from 'src/hooks/useSegmentEvents';

interface SignupPageProps {
    location: WindowLocation;
}

const SignupPage = ({ location: { state } }: SignupPageProps) => {
    const { sendPageViewEvent } = useSegmentEvents();
    useEffect(() => {
        sendPageViewEvent({
            name: 'Account Creation',
            path: SIGN_UP_PATH,
            title: 'Account Creation | Pvolve',
            source_app: 'Web app',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={authStyles.formContainer}>
            <SEO title="Pvolve Signup" />
            <Typography variant="h5">Create Your Account</Typography>
            <SignUpForm email={state?.email || ''} redirectUrl={state?.redirectUrl} />
        </div>
    );
};

export default withLocation(SignupPage);
