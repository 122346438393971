import { useCallback } from 'react';
import Actions from 'src/state/root-actions';

export const useSegmentEvents = () => {
    const sendPageViewEvent = useCallback(async (properties?: Record<string, unknown>) => {
        await Actions.segment.page(properties);
    }, []);
    return {
        sendPageViewEvent,
    };
};
export default useSegmentEvents;