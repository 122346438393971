import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { withFormik } from 'formik';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { Button, Checkbox, Form, FormProps, Grid, Label } from 'semantic-ui-react';
import { IState } from '@pvolve/sdk/src/redux/selectors';

import Selectors from 'src/state/root-selectors';
import Actions from 'src/state/root-actions';
import Banner from 'src/components/shared/Banner';
import { SUI_GRID_CENTERED } from 'src/utils/semantic-utils';
import {
    FORM_MESSAGES,
    MAX_INPUT_LENGTH,
    getErrorWithBkgMessage,
    getInfoMessage,
    hasAllValues,
    validatePassword,
} from 'src/utils/form-utils';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';

import * as authStyles from 'src/styles/auth.module.scss';

const connector = connect((state: IState) => ({
    loggedIn: Selectors.auth.loggedIn(state),
}));

interface SignupFormFields {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    optIn: boolean;
    acceptTerms: boolean;
}

interface SignupFormFieldErrors {
    email?: string;
    firstName?: string;
    lastName?: string;
    general?: string;
    password?: string;
    acceptTerms?: string;
}

interface SignUpFormProps extends ConnectedProps<typeof connector> {
    isSubmitting: boolean;
    touched: SignupFormFields;
    errors: SignupFormFieldErrors;
    email: string;
    values: SignupFormFields;
    handleChange: ((event: React.ChangeEvent<HTMLInputElement>, data: any) => void) | undefined;
    handleBlur: Function; // TODO
    handleSubmit: ((event: React.FormEvent<HTMLFormElement>, data: FormProps) => void) | undefined;
}

const SignUpForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    loggedIn,
    email,
}: SignUpFormProps) => {
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => setShowPassword((showPasswordState) => !showPasswordState);
    const shopifyUrl = useSelector(PvolveSelectors.config.shopifyUrl);

    useEffect(() => {
        values.email = email;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { widths } = SUI_GRID_CENTERED;

    const togglePassword = () => (
        <span className={authStyles.togglePassword} onClick={toggleShowPassword}>
            Show
        </span>
    );

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container centered>
                <Grid.Row>
                    <Grid.Column
                        mobile={widths.mobile}
                        tablet={widths.tablet}
                        computer={widths.computer}
                    >
                        {errors?.general && <Banner type="ERROR">{errors.general}</Banner>}
                        <Form.Field>
                            <Form.Input
                                id="signup-email"
                                name="email"
                                placeholder="Email"
                                className={`${authStyles.input} ${authStyles.inputEmail}`}
                                type="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={email || values.email}
                                error={
                                    errors.email && touched.email
                                        ? getErrorWithBkgMessage(errors.email)
                                        : false
                                }
                                disabled={!!email}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                id="signup-first-name"
                                name="firstName"
                                placeholder="First Name"
                                className={authStyles.input}
                                maxLength={MAX_INPUT_LENGTH}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.firstName}
                                error={
                                    errors.firstName && touched.firstName
                                        ? getErrorWithBkgMessage(errors.firstName)
                                        : false
                                }
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                id="signup-last-name"
                                name="lastName"
                                placeholder="Last Name"
                                className={authStyles.input}
                                maxLength={MAX_INPUT_LENGTH}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.lastName}
                                error={
                                    errors.lastName && touched.lastName
                                        ? getErrorWithBkgMessage(errors.lastName)
                                        : false
                                }
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                className={`${authStyles.passwordIconButton} ${authStyles.input}`}
                                icon={togglePassword}
                                id="signup-password"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Add Password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                error={
                                    errors.password && touched.password
                                        ? getErrorWithBkgMessage(errors.password)
                                        : false
                                }
                            />
                            <div className={authStyles.passwordValidationMessage}>
                                {!(errors.password && touched.password) &&
                                    getInfoMessage(FORM_MESSAGES.passwordShortValidation)}
                            </div>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column
                        mobile={widths.mobile}
                        tablet={widths.tablet}
                        computer={widths.computer}
                    >
                        <Form.Checkbox
                            id="optIn"
                            name="optIn"
                            label="Keep me up to date with P.volve news and exclusive offers"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            checked={values.optIn}
                            className={authStyles.checkbox}
                        />
                        <Form.Checkbox
                            id="acceptTerms"
                            name="acceptTerms"
                            label={
                                <label>
                                    I agree to{' '}
                                    <a
                                        href={`${shopifyUrl}/policies/terms-of-service`}
                                        target="_blank"
                                        className="underline"
                                        rel="noreferrer"
                                    >
                                        Terms of Service
                                    </a>{' '}
                                    and{' '}
                                    <a
                                        href={`${shopifyUrl}/pages/fitness-class-waiver`}
                                        target="_blank"
                                        className="underline"
                                        rel="noreferrer"
                                    >
                                        Liability Waiver*
                                    </a>
                                </label>
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            checked={values.acceptTerms}
                            className={authStyles.checkbox}
                            error={
                                errors.acceptTerms && touched.acceptTerms
                                    ? getErrorWithBkgMessage(errors.acceptTerms)
                                    : false
                            }
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column
                        mobile={widths.mobile}
                        tablet={widths.tablet}
                        computer={widths.computer}
                    >
                        <Button
                            className={authStyles.submitButton}
                            type="submit"
                            disabled={!hasAllValues(values) || !isEmpty(errors) || loggedIn}
                            loading={isSubmitting}
                            primary
                            fluid
                        >
                            Create Account
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

const handleSubmit = (formValues, { props, setSubmitting, setFieldError }) => {
    const email = formValues.email?.toLowerCase();
    window.localStorage.removeItem('marianaTekSubsEmail')

    props.dispatch(
        Actions.segment.track.appButtonClicked({
            app_button_name: 'Account Creation',
            app_button_text: 'Create account',
            app_button_target_url: `https://${
                process.env.STAGE === 'prod' ? '' : process.env.STAGE + '.'
            }pvolve.com/`,
            app_button_target_domain: `${
                process.env.STAGE === 'prod' ? '' : process.env.STAGE + '.'
            }pvolve.com`,
            app_button_target_path: '/',
            source_app: 'Web app',
        })
    );
    const onSignupFailure = (message) => setFieldError('general', message);
    const onSignupFulfill = () => setSubmitting(false);
    const onSingupSuccess = () => {
        if (formValues.optIn && formValues.email) {
            props.dispatch(
                Actions.segment.identify({
                    userId: email,
                    traits: {
                        email,
                        email_marketing_opted_in: 'opted_in',
                    },
                })
            );
            props.dispatch(
                Actions.segment.track.marketingOptedIn({
                    email,
                    source_detail: 'signup',
                    source: 'app.pvolve.com',
                    is_email_opt_in: true,
                })
            );
        }
    };

    props.dispatch(
        Actions.auth.signup.trigger({
            ...formValues,
            email,
            onFailure: onSignupFailure,
            onFulfill: onSignupFulfill,
            onSuccess: onSingupSuccess,
        })
    );
};

const validate = (values: SignupFormFields) => {
    const error: SignupFormFieldErrors = {};

    if (!values.email?.trim()) {
        error.email = FORM_MESSAGES.emailRequired;
    }

    if (!values.firstName?.trim()) {
        error.firstName = FORM_MESSAGES.firstNameRequired;
    }

    if (!values.lastName?.trim()) {
        error.lastName = FORM_MESSAGES.lastNameRequired;
    }

    const passwordError = validatePassword(values);
    if (passwordError.password) {
        error.password = passwordError.password;
    }

    if (!values.acceptTerms) {
        error.acceptTerms = FORM_MESSAGES.termsRequired;
    }

    return error;
};

const FormikSignUpForm = withFormik({
    handleSubmit,
    validate,
    mapPropsToValues: (props) => ({
        email: props.email || '',
        firstName: '',
        lastName: '',
        password: '',
        optIn: false,
        acceptTerms: false,
    }),
})(SignUpForm);

export default connector(FormikSignUpForm);
